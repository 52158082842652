<script setup>
import { onMounted, ref } from 'vue';

defineProps({
    modelValue: {
        type: String,
        required: true,
    },
  placeholder: {
      type: String,
      required:true,
  },
  icon: {
    type: URL,
    required:true,
  },
  type: {
    type: String,
    required: true,
    default: 'text'
  }

});

defineEmits(['update:modelValue']);

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
  <div class="relative p-0 bg-transparent border-0">
    <img v-if="icon" class='absolute left-4 top-[36px] ' :src="icon" alt='form icon'/>
    <input
        class="w-full rounded-md border-[#96939B] placeholder-[#96939B] pl-12 py-4 bg-transparent mt-4 text-white"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        ref="input"
        :placeholder="placeholder"
        :type="type"
    />
  </div>
</template>
